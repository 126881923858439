import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactFullpage from '@fullpage/react-fullpage';

import Layout from '../components/layout';
import SEO from '../components/seo';
import localize from '../components/localize';
import Footer from '../components/footer';

const DesignPage = ({ data }) => {
  const projectPage = data.projectPage.edges.map(edge => edge.node);
  const projects = projectPage[0].projects.map(project => project);

  const [activeSection, setActiveSection] = useState();
  const afterLoad = (origin, destination) => {
    setActiveSection(destination.index);
  };

  return (
    <>
      <Layout
        current={projectPage[0].title}
        dePath="/design"
        enPath="/en/design"
        hideNav={activeSection === 1}
        disableUp={activeSection === 0}
        disableDown={activeSection === 0}
        onClickUp={() => window.fullpage_api.moveSectionUp()}
        onClickDown={() => window.fullpage_api.moveSectionDown()}
      >
        <SEO siteTitle={projectPage[0].title} />
        <ReactFullpage
          licenseKey={process.env.GATSBY_FULLPAGE_LICENSE}
          responsiveWidth={1020}
          scrollOverflow
          autoScrolling
          scrollingSpeed={600}
          easingcss3="cubic-bezier(0.700, 0.000, 1.000, 1.000)"
          touchSensitivity="100"
          afterLoad={afterLoad}
          render={() => (
            <div id="fullpage-wrapper">
              <StyledProjectsSection className="section light fp-auto-height-responsive">
                <div className="inner">
                  {projects.map((project, i) => (
                    <Link key={i} to={project.slug.current}>
                      <div className="project">
                        <div className="text">
                          <h3 className="headline big">{project.title}</h3>
                          <p className="subline">
                            <span>{project.when}</span>
                            <span
                              style={{
                                padding: '0 calc(var(--spacing-XXS) / 3)',
                              }}
                            >
                              —
                            </span>
                            <span>(CLIENT) </span>
                            <span>{project.client}</span>
                          </p>
                        </div>
                        <div className="images">
                          {(project.images[0].__typename ===
                            'SanityMainImage' && (
                            <StyledImage
                              fluid={project.images[0].image.asset.fluid}
                              alt={project.images[0].alt}
                              className="img-1"
                            />
                          )) ||
                            (project.images[0].__typename === 'SanityVideo' && (
                              <figure className="img-1">
                                <video
                                  src={project.images[0].videoUrl}
                                  playsInline
                                  loop
                                  autoPlay
                                  muted
                                />
                              </figure>
                            ))}
                          {(project.images[1].__typename ===
                            'SanityMainImage' && (
                            <StyledImage
                              fluid={project.images[1].image.asset.fluid}
                              alt={project.images[1].alt}
                              className="img-2"
                            />
                          )) ||
                            (project.images[1].__typename === 'SanityVideo' && (
                              <figure className="img-2">
                                <video
                                  src={project.images[1].videoUrl}
                                  playsInline
                                  loop
                                  autoPlay
                                  muted
                                />
                              </figure>
                            ))}
                          {(project.images[2].__typename ===
                            'SanityMainImage' && (
                            <StyledImage
                              fluid={project.images[2].image.asset.fluid}
                              alt={project.images[2].alt}
                              className="img-3"
                            />
                          )) ||
                            (project.images[2].__typename === 'SanityVideo' && (
                              <figure className="img-3">
                                <video
                                  src={project.images[2].videoUrl}
                                  playsInline
                                  loop
                                  autoPlay
                                  muted
                                />
                              </figure>
                            ))}
                          {(project.images[3].__typename ===
                            'SanityMainImage' && (
                            <StyledImage
                              fluid={project.images[3].image.asset.fluid}
                              alt={project.images[3].alt}
                              className="img-4"
                            />
                          )) ||
                            (project.images[3].__typename === 'SanityVideo' && (
                              <figure className="img-4">
                                <video
                                  src={project.images[3].videoUrl}
                                  playsInline
                                  loop
                                  autoPlay
                                  muted
                                />
                              </figure>
                            ))}
                          {(project.images[4].__typename ===
                            'SanityMainImage' && (
                            <StyledImage
                              fluid={project.images[4].image.asset.fluid}
                              alt={project.images[4].alt}
                              className="img-5"
                            />
                          )) ||
                            (project.images[4].__typename === 'SanityVideo' && (
                              <figure className="img-5">
                                <video
                                  src={project.images[4].videoUrl}
                                  playsInline
                                  loop
                                  autoPlay
                                  muted
                                />
                              </figure>
                            ))}
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </StyledProjectsSection>
              <Footer />
            </div>
          )}
        />
      </Layout>
    </>
  );
};

const StyledProjectsSection = styled.section`
  .inner {
    padding: var(--spacing-XL) var(--spacing-XXS) var(--spacing-S)
      var(--spacing-XS);
    width: 100vw;

    .project {
      padding-bottom: var(--spacing-XXS);
      display: flex;
      align-items: center;

      .text {
        width: 50%;
        padding-right: var(--spacing-XXS);
        z-index: 1;
      }

      .images {
        width: 50%;
        display: flex;
      }

      figure {
        flex: 1;
        width: 100%;
        margin-right: var(--spacing-XXS);

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    @media (max-width: 1020px) {
      padding: var(--spacing-L) 0 var(--spacing-S) var(--spacing-XXS);

      .subline {
        display: none;
      }

      .text {
        width: 75%;
      }

      .images {
        width: 25%;

        .img-3,
        .img-4,
        .img-5 {
          display: none;
        }
      }
    }
  }
`;

const StyledImage = styled(Img)`
  margin-right: var(--spacing-XXS);
  flex: 1;
`;

export const query = graphql`
  query Design {
    projectPage: allSanityDesignPage {
      edges {
        node {
          title
          projects {
            title
            when
            client
            slug {
              current
            }
            images {
              ... on SanityMainImage {
                __typename
                alt
                image {
                  asset {
                    fluid(maxWidth: 3600) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityVideo {
                __typename
                videoUrl
              }
            }
          }
        }
      }
    }
  }
`;

DesignPage.propTypes = {
  data: PropTypes.object,
};

export default localize(DesignPage);
